const createDataTestId =
	(suffix: string | 'productCard') =>
	(productId?: string | 'productCard', indexImage?: number) =>
		`${productId}${suffix}${indexImage ?? ''}`

export const dataTestIds = {
	productCardImage: createDataTestId('-slot'),
	productCardFavoriteActive: createDataTestId('.favorite.heart.active'),
	productCardFavoriteInactive: createDataTestId('.favorite.heart.inactive'),
	productCardPlusSize: createDataTestId('.overlay.size.plus'),
	productCardNewNowTag: createDataTestId('.tag.new.now'),
	productCardTag: createDataTestId('.tag'),
	productCardSlideshow: createDataTestId('.slideshow'),
	productCardSizePanel: createDataTestId('.sizeSelector.panel'),
	productCardSizeAvailable: createDataTestId('.sizeSelector.available'),
	productCardSizeUnavailable: createDataTestId('.sizeSelector.unavailable'),
	productCardColorButton: (colorId: string) =>
		`productCard.colorPicker.button.${colorId}`,
	productCardFeatured: createDataTestId('.featured'),
}
