'use client'

import { useCartController } from 'cart/hooks/useCartController'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { IconPlusSmall } from 'icons/components/IconPlusSmall'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { SizeSheet } from 'product-card/components/shared/ProductSizes/SizeSheet'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardStock } from 'product-card/hooks/useProductCardStock'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { useProductStock } from 'product/services/productStock/client'
import type { Product } from 'product/types'
import { useCallback, useMemo, useRef } from 'react'

import styles from './SizeSheetIcon.module.scss'
import fukkuStyles from 'fukku/styles/classes.module.scss'

/* eslint react-hooks/exhaustive-deps: warn */

interface AddToBagButtonProps {
	product: Product
}

export const SizeSheetIcon = ({ product }: AddToBagButtonProps) => {
	const {
		country: { countryISO },
	} = useMasterData()
	const { layoutView } = useProductCardConsumerLayoutContext()

	const sizeSheetRef = useRef<ModalAPI>(null)
	const { t } = useLabels()

	const { productId, selectedColorId, lookId, location } =
		useProductCardContext()
	const { productColors } = useProductColors({ product })
	const { add } = useCartController()

	const productColorInfo = useMemo(
		() =>
			productColors?.find(
				(productColor) => productColor.id === selectedColorId
			),
		[productColors, selectedColorId]
	)

	const { data: productStock } = useProductStock({
		productId,
		country: countryISO,
	})

	const { productSizesFullInfo } = useProductCardStock({
		productStock,
		productColors,
	})

	const hasSizes = productColorInfo && productColorInfo.sizes.length > 1
	const isOneSize = productColorInfo?.sizes.length === 1

	const onOpen = useCallback(() => sizeSheetRef.current?.show(), [])
	const onClose = useCallback(() => sizeSheetRef.current?.close(), [])

	// TODO: remove this when we send the seller in the add to bag request
	const productSeller = 'mango'

	const onAdd = useCallback(() => {
		if (isOneSize) {
			add(
				{
					productId,
					lookId,
					layoutView,
					sizeId: productColorInfo.sizes[0].id,
					seller: productSeller,
					colorId: selectedColorId,
					lastUnits: !!productSizesFullInfo?.[0].lastUnits,
				},
				location
			)
		}
	}, [
		add,
		isOneSize,
		layoutView,
		location,
		lookId,
		productColorInfo?.sizes,
		productId,
		productSizesFullInfo,
		selectedColorId,
	])

	return (
		<>
			<ButtonIconTransparent
				className={styles.button}
				onClick={hasSizes ? onOpen : onAdd}
				data-testid='productCard.sizeSheetIconButton'
			>
				<IconPlusSmall className={styles.icon} />
				<span
					className={fukkuStyles.srOnly}
					data-testid={
						hasSizes
							? 'productCard.sizeSheetIconButton.multiSize'
							: 'productCard.sizeSheetIconButton.oneSize'
					}
				>
					{t('productCard.showSizes.button')}
				</span>
			</ButtonIconTransparent>
			{hasSizes && (
				<Modal
					data-testid='productCard.sizeSheet'
					name='productCard-size-sheet'
					title={t('productCard.selectSize.title')}
					type={MODAL_TYPE.SHEET_SMALL}
					showTitle={true}
					ref={sizeSheetRef}
				>
					<SizeSheet product={product} onClose={onClose} />
				</Modal>
			)}
		</>
	)
}
