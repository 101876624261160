'use client'

import { useAnalyticsImpressions } from 'analytics/contexts/AnalyticsImpressionsProvider'
import type { Item } from 'analytics/utils/getEECItems/getEECItems'
import { Slideshow } from 'fukku/Slideshow'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { PdpClientLink } from 'links/pdp/client'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardHoverContext } from 'product-card/hooks/useProductCardHoverContext'
import { useProductCardImagesSizesContext } from 'product-card/hooks/useProductCardImagesSizesContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { ProductCardVariant } from 'product-card/types'
import { generateImagesSizes } from 'product-card/utils/generateImagesSizes'
import { getProductLinkLabel } from 'product-card/utils/getProductLinkLabel/getProductLinkLabel'
import { getSortedImages } from 'product-card/utils/getSortedImages'
import type { Product, ProductImageType } from 'product/types'
import { useEffect, useMemo, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { env } from 'utils/envManager'

import SmartCropImage from './SmartCropImage/SmartCropImage'

import styles from './ProductImage.module.scss'
import fukkuStyles from 'fukku/styles/classes.module.scss'

type ImageDisplayType = 'single' | 'slider'

interface ProductImageProps {
	product: Product
	imageSortCriteria: ProductImageType[]
	variant: ProductCardVariant
	priority?: boolean
	showAsFeatured?: boolean
}

const IMPRESSION_THRESHOLD = 0.5

export const ProductImage = ({
	product,
	imageSortCriteria,
	variant,
	priority,
	showAsFeatured,
}: ProductImageProps) => {
	const { isTouchable } = useResponsive()

	const {
		lookId,
		selectedColorId,
		dataTestId,
		productId,
		productListId,
		draggable,
	} = useProductCardContext()
	const { shouldRenderSlider, shouldRenderFeatured, layoutView } =
		useProductCardConsumerLayoutContext()
	const { imagesSizes } = useProductCardImagesSizesContext()
	const { selectedColor } = useProductColors({ product })
	const { addImpression } = useAnalyticsImpressions()
	const productImageRef = useRef<HTMLImageElement | null>(null)
	const entry = useIntersectionObserver(productImageRef, {
		threshold: IMPRESSION_THRESHOLD,
		freezeOnceVisible: true,
	})

	const { isHovered } = useProductCardHoverContext()

	const sortedImages = useMemo(
		() =>
			getSortedImages({
				product,
				imageSortCriteria,
				showAsFeatured,
				lookId,
				shouldRenderFeatured,
				colorId: selectedColorId,
			}),
		[
			product,
			imageSortCriteria,
			showAsFeatured,
			shouldRenderFeatured,
			selectedColorId,
			lookId,
		]
	)

	const imageDisplayType = useMemo<ImageDisplayType>(() => {
		if (sortedImages.length === 1 || !shouldRenderSlider) {
			return 'single'
		}
		return 'slider'
	}, [shouldRenderSlider, sortedImages.length, variant])

	const currentImages =
		isHovered || !isTouchable ? sortedImages : sortedImages.slice(0, 1)

	const sizes = useMemo(
		() =>
			generateImagesSizes(
				showAsFeatured ? imagesSizes.featured : imagesSizes.regular
			),
		[imagesSizes]
	)

	useEffect(() => {
		if (entry?.isIntersecting) {
			const item: Item = {
				colorId: selectedColorId,
				listId: productListId,
				quantity: 1,
				lookId,
				productId,
				layoutView,
			}
			addImpression(item)
		}
	}, [entry?.isIntersecting, productId])

	const metaImage = (
		<meta
			itemProp='image'
			content={new URL(sortedImages[0].img, env.NEXT_PUBLIC_SITE_BASE_URL).href}
		/>
	)

	if (imageDisplayType === 'single') {
		return (
			<div className={styles.productImage} data-testid='productCard.image'>
				<div
					className={styles.imageWrapper}
					data-testid='productCard.image.wrapper'
				>
					<PdpClientLink
						product={product}
						lookId={lookId}
						colorId={selectedColorId}
						listId={productListId}
						linkProps={{
							className: styles.link,
							linkProps: {
								'aria-label': getProductLinkLabel(product, selectedColorId),
								draggable,
							},
						}}
					>
						{metaImage}
						<SmartCropImage
							data-testid={dataTestIds.productCardImage(dataTestId, 0)}
							alt={sortedImages[0].alt}
							src={sortedImages[0].img}
							sizes={sizes}
							priority={priority}
							ref={productImageRef}
							showAsFeatured={showAsFeatured}
							draggable={draggable}
							fill
						/>
						<span className={fukkuStyles.srOnly}>
							{product.name} {selectedColor?.label} ({product.reference})
						</span>
					</PdpClientLink>
				</div>
			</div>
		)
	}

	if (imageDisplayType === 'slider') {
		return (
			<div
				className={styles.productImage}
				data-testid='productCard.images'
				ref={productImageRef}
			>
				<PdpClientLink
					product={product}
					lookId={lookId}
					colorId={selectedColorId}
					listId={productListId}
					metaAs='url'
					linkProps={{
						linkProps: {
							'aria-label': getProductLinkLabel(product, selectedColorId),
							draggable,
						},
					}}
				>
					{metaImage}
					<Slideshow
						className={styles.slideShow}
						isTouchable={isTouchable}
						dataTestId={dataTestIds.productCardSlideshow(dataTestId)}
						hasPreload
					>
						{currentImages?.map((image, index) => (
							<div key={image.alt} className={styles.imageWrapper}>
								<SmartCropImage
									data-testid={dataTestIds.productCardImage(dataTestId, index)}
									alt={image.alt}
									src={image.img}
									sizes={sizes}
									priority={priority && index === 0}
									showAsFeatured={showAsFeatured}
									fill
									draggable={draggable}
								/>
							</div>
						))}
					</Slideshow>
				</PdpClientLink>
			</div>
		)
	}

	return null
}
