import { useBackInStockModalContext } from 'back-in-stock/src/hooks/useBackInStockModalContext/useBackInStockModalContext'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import type { Product, ProductFullSizeInfo } from 'product/types/Product'

import styles from './BackInStock.module.scss'
import fukkuStyles from 'fukku/styles/classes.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface AddButtonProps {
	product: Product
	size: ProductFullSizeInfo
	className?: string
	isSizeLabel?: boolean
	onClose?: () => void
	children?: React.ReactNode
}

export const BackInStockButton = ({
	product,
	size,
	className,
	isSizeLabel = true,
	onClose,
	children,
}: AddButtonProps) => {
	const { t } = useLabels()
	const { productId, dataTestId } = useProductCardContext()
	const { selectedColor } = useProductColors({ product })
	const { openProductSubscription } = useBackInStockModalContext()

	const sizeDataTestId =
		dataTestIds[
			size.available ? 'productCardSizeAvailable' : 'productCardSizeUnavailable'
		](dataTestId)

	const handleOnClick = () => {
		if (selectedColor) {
			openProductSubscription({
				productId,
				productSizeId: size.id,
				productName: product.name,
				productColorId: selectedColor.id,
				feedbackDelay: 0,
			})
		}

		if (onClose) {
			onClose()
		}
	}

	return (
		<>
			<button
				className={`${text.bodyL} ${styles.disabled} ${className}`}
				onClick={handleOnClick}
				data-testid={sizeDataTestId}
			>
				{isSizeLabel && size.label}
				{children}
			</button>
			<span className={fukkuStyles.srOnly}>
				{t('product.general.addSize.selector.title')}
			</span>
		</>
	)
}
