'use client'

import { FavoriteButtonSmall } from 'favorite/src/FavoriteButtonSmall'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import type { Product } from 'product/types'

import styles from './FavoriteButton.module.scss'

interface FavoriteButtonProps {
	product: Product
	productId: string
}

export const FavoriteButton = ({ product, productId }: FavoriteButtonProps) => {
	const { selectedColor } = useProductColors({ product })
	const { dataTestId, location } = useProductCardContext()

	if (selectedColor) {
		return (
			<FavoriteButtonSmall
				className={styles.favoriteButton}
				productId={productId}
				selectedColorId={selectedColor.id}
				dataTestIds={{
					active: dataTestIds.productCardFavoriteActive(dataTestId),
					inactive: dataTestIds.productCardFavoriteInactive(dataTestId),
				}}
				location={location}
				notification
			/>
		)
	}

	return null
}
