'use client'

import Image from 'next/image'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useColorSheetTimeoutContext } from 'product-card/hooks/useColorSheetTimeoutContext/useColorSheetTimeoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { reorderColorsById } from 'product-card/utils/reorderColorsById'
import { sendChangeColorEvent } from 'product/analytics/sendChangeColorEvent'
import type { ProductColor } from 'product/types'
import { GenericEvents as ProductGenericEvents } from 'product/types/GenericEvents'

import styles from './ColorSheetBody.module.scss'

interface ColorSheetBodyProps {
	productColors: ProductColor[]
	onClose: () => void
}

interface HandleClickProps {
	colorId: string
	relatedProductId: string | undefined
}

export const ColorSheetBody = ({
	productColors,
	onClose,
}: ColorSheetBodyProps) => {
	const { setCustomTimeout } = useColorSheetTimeoutContext()
	const {
		defaultColorId,
		selectedColorId,
		setSelectedColorId,
		setProductId,
		productId,
		location,
	} = useProductCardContext()

	const isSelectedColor = (colorId: string) => colorId === selectedColorId
	const closeDelaySeconds = 2000

	const orderColorSheetInfo = reorderColorsById({
		productColors,
		defaultColor: defaultColorId,
	})

	const handleClick = ({ colorId, relatedProductId }: HandleClickProps) => {
		setSelectedColorId(colorId)
		sendChangeColorEvent({
			eventName: ProductGenericEvents.PLP_ACTION,
			productId,
			colorId,
			location,
		})

		if (relatedProductId) {
			setProductId(relatedProductId)
		}

		setCustomTimeout(onClose, closeDelaySeconds)
	}

	return (
		<>
			<div className={styles.colorSheetBody}>
				{orderColorSheetInfo.map((color) => (
					<button
						key={`${color.colorId}-${color.colorLabel}`}
						aria-label={color.colorLabel}
						className={styles.colorButton}
						onClick={() =>
							handleClick({
								colorId: color.colorId,
								relatedProductId: color.relatedProductId,
							})
						}
						data-testid={dataTestIds.productCardColorButton(color.colorId)}
					>
						<Image
							className={isSelectedColor(color.colorId) ? styles.selected : ''}
							alt={color.colorId}
							src={color.bulletImg}
							width={24}
							height={24}
							sizes='24px'
						/>
					</button>
				))}
			</div>
		</>
	)
}
